@import '~remixicon/fonts/remixicon.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{box-sizing: border-box;}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  text-decoration: none !important;
}
:root{
  --anim-duration: 100ms;
  --header-height: 100px;
  --mini-header-height: 62px;
}
.App{position: relative;}
/****** header  ******/
.header{width: 100%;position: fixed;background-color: rgba(255, 255, 255, 0);z-index: 1000;transition: background-color 0.3s, box-shadow 0.3s;transition: transform calc(var(--anim-duration)* 6) cubic-bezier(.165,.84,.44,1), background calc(var(--anim-duration)* 3);letter-spacing: 0.05em;}
.header .depthbtn{opacity: 0;visibility: 0;}
.header.white .depth1,.header.white .depth2 a{color: #fff;}
.header.white.hover .depth1,.header.white.hover .depth2 a{color: #000;}
.header.white.hover:hover .depth2 a:hover{color: blue;}
.header.white.fixed .depth1,.header.white.fixed .depth2 a{color: #000;}
.header.white::before{background: #ffffffc7;;}
.header::before{content: "";position: absolute;top: 0;left: 0;right: 0;background: rgb(8 15 61 / 78%);height: var(--header-bg-height, 0%);opacity: var(--header-bg-opacity, 1);transition: height calc(var(--anim-duration)* 5) cubic-bezier(.23,1,.32,1), opacity calc(var(--anim-duration)* 3);transition-delay: calc(var(--anim-duration)* 1);}
.header.hover::before {height: calc(var(--depth2-height, 100%)) !important; opacity: 1;}
.header.fixed::before{height: var(--header-bg-height, 100%);box-shadow: 0px 4px 10px rgb(9 26 70 / 78%);}
.header .container{padding: 0 clamp(40px, 3.125vw, 80px);margin: 0 auto;}
.header .header-content{width: 100%;max-width: 192rem;display: flex;justify-content: center;margin: auto;position: relative;height: var(--header-height, 100px);padding-left: 7rem;}
.header .logo{position: absolute;top: 50%;transform: translateY(-50%);left: 0;}
.header .logo img{width:248px; height:100px; object-fit: contain; }
.header .depth1{line-height: 6rem;font-weight: 700;padding-bottom: .6rem;transition: color 0.3s ease; font-size:18px;position: relative;display: block;font-size: 1.4rem;}
.header a{text-decoration: none; color: #fff;}
.header .nav .gnb{display: flex;}
.header button{opacity: 0;visibility: hidden;position: absolute;}
.header .nav ul{list-style-type: none;padding: 0;margin: 0;gap: 5.1333333333em;}
.header .depth2 ul{gap: .4333333333em; display: grid;place-items: start;}
.header .depth2 ul a{white-space: nowrap;}
.header .gnb > li{position: relative;}
.header .depth2{position: absolute;opacity: 0;visibility: hidden;transition: opacity 0.3s ease, visibility 0.3s ease;left: 0;height: auto;
  transform: translateY(0em);
  transition: opacity calc(var(--anim-duration)* 3), 
  visibility calc(var(--anim-duration)* 3), 
  transform calc(var(--anim-duration)* 5) cubic-bezier(.19,1,.22,1);
  font-size: 1.2rem;
}
.depth2.click{opacity: 1 !important; visibility: visible;}
.header .depth2 li{display: inline-flex;}
.gnb .depth1[aria-expanded="true"] + .depth2 {opacity: 1;visibility: visible;transform: translateY(-1.6em);}
.gnb .depth1[aria-expanded="true"] .subcontent {height: calc(var(--depth2-height, 100%));}
.depth1.hover, .depth2.hover {color: #007bff !important; }
.depth1.underline::after {content: "";position: absolute;bottom: 0; left: 0;width: 100%;height: 2px; background-color: #007bff;animation: underlineEnter 0.3s forwards; transform: translateY(-2em);}
.depth2 a{transition: color 0.3s ease;}
.depth2 a:hover{color: #007bff !important;}
@keyframes underlineEnter {
  from {
    width: 100%;
    left: 0;
  }
  to {
    width: 0;
    left: 100%;
  }
}
@keyframes underlineExit {
  from {
    width: 0;
    left: 100%;
  }
  to {
    width: 100%;
    left: 0;
  }
}

/****** main ******/
.main .main_wrap{max-width: 101rem;min-width: 1012px;padding: 0 2rem;}
.main_wrap{position: relative !important;padding-bottom: 2rem;max-width: calc(100% - 4rem);margin-right: auto;margin-left: auto;position: relative;}
.main .section{padding: 5.6rem 0 5rem;}
.main .section.visual2{padding: 5.6rem 0 0rem;padding-top: 0;}
.main .visual1{width: 100%;display: flex;align-items: center;justify-content: space-between;}
.main .visual1 .about{display: flex;max-width: 32.5rem;align-items: center;text-align: left;}
.main .visual1 .about-l h1{font-size: 3rem;text-align: left;font-weight: 900;}
.main .visual1 .about-text{padding-left: 1.1rem;}
.main .visual1 .about-text h2{margin: 0; font-size: 1.8rem;display: flex;}
.main .visual1 .about-text p{font-size: 1.02rem;margin-top: 0.5rem;font-weight: 500;}
.main .visual1 img{display: block;border-radius: 50%;width: 7.5rem;height: 7.5rem;transform: scale(1.1);}
.main .visual1 .about-img{position: relative;padding: 0.8rem;border-radius: 50%;}
.main .visual1 .about-img::before{animation: rotate 20s infinite linear;content: "";position: absolute;left: 0;top: 0;width: 100%;height: 100%;border: 2px dashed #2F5BC1;border-radius: 50%;}
.main .motion{opacity: 0;position: relative;transition: all .8s ease;transform: translateY(3rem);}
.main .section.action{top: 0;opacity: 1;transform: translateY(0);}
.blue{color: #007bff !important;}
@keyframes rotate{
  100%{transform:rotate(360deg)}
}

.main .visual2 ul{list-style-type: none;padding: 0;display: flex;justify-content: space-around;}
.main .visual2 ul li{list-style-type: none;transition: all 0.3s ease;margin-right: 0.8rem;width: calc(25% - 2.8rem);width: 25%;border-radius: 1rem;}
.main .visual2 ul li:hover {transform: translateY(-2rem);box-shadow: 0px 4px 10px rgb(9 26 70 / 78%);border-radius: 1rem;}
.main .visual2 ul li:hover .item {color: #fff;border-radius: 1rem;}
.main .visual2 ul li:hover .desc strong {color: #fff;position: relative;}
.main .visual2 ul li:hover .desc strong::after{content: "";position: absolute;bottom: -2.7rem; left: 0;width: 100%;height: 2px; background-color: #007bff;animation: underlineEnter 0.3s forwards; transform: translateY(-2em);}
.main .visual2 ul li:hover .desc p {color: #e0e0e0;}
.main .visual2 ul li:hover .icon {background: #e0e0e0;}
.main .visual2 ul li:hover .icon-img {opacity: 1;color: #007bff;}
.main .visual2 a{text-decoration: none;}
.main .visual2 .item{display: flex; align-items: flex-end;}
.main .visual2 .item {display: flex;height: 100%;padding: 2.1rem 1.1rem;background: #F4F6F8;border-radius: 1rem;align-items: center;transition: all 0.3s ease;}
.main .visual2 ul li:hover .item {background-color: #007bff;border-radius: 1rem;}
.main .visual2 .desc{padding-left: 1.7rem;letter-spacing: -.05rem;text-align: left;}
.main .visual2 .icon{width: 6.6rem;height: 6.6rem;border-radius: 100%;overflow: hidden;background: #fff;flex-shrink: 0;position: relative;}
.main .visual2 .icon .icon-img{font-size: 5rem;opacity: .5;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);color: rgb(25 50 86);;}
.main .visual2 img{max-width: 100%;border: 0 none;font-size: 0;vertical-align: middle;position: relative;top: 0.7rem;}
.main .visual2 .desc strong{display: inline-block;position: relative;font-size: 1.4rem;font-weight: 800;color: #000;transition: all .2s;}
.main .visual2 .desc p{margin-top: 0.5rem;color: #4D4D4D;line-height: 1.6rem;font-size: 1.1rem;transition: all .2s;}
.main .visual2 li{margin-right: 3.2rem;width: calc(25% - 2.8rem);}
.main .quotes{font-size: 1.2rem;}
.main .comma{font-size: 1.2rem;transform: rotate(10deg);position: relative;top: 14px;}

.main .section.visual3{display: flex;align-items: flex-start;justify-content: space-between;}
.main .section.visual3 .noitce{border: 0.07rem solid #e2e2e2; padding: 3rem 1.6rem;border-radius: 1rem;max-height: 571px;width: 52%;}
.main .section.visual3 .noitce ul{display: flex;flex-direction: column; gap: 1rem;}
.main .section.visual3 .noitce ul li{padding: 0 1rem 1.2rem 5rem; border-bottom: 0.07rem dashed #e2e2e2;position: relative;line-height: 1.9rem;text-align: left;height: 3.5rem;}
.main .section.visual3 .visual3m{text-align: left;font-size: 1.7rem;margin: 0;margin-bottom: 1.7rem;font-weight: 600;}
.main .section.visual3 .noitce em{background: #E5E5E5;padding: 0rem 1rem;color: #000; font-weight: 600;border-radius: 1rem;font-style: normal;position: absolute;top: 0;left: 0;font-weight: 900;}
.main .section.visual3 .noitce strong{font-size: 1.1rem;font-weight: 600;}
.main .section.visual3 ul{list-style-type: none;padding: 0;height: 24.5rem;}
.main .section.visual3 .noitce a{text-decoration: none;color: #000;}
.main .section.visual3 .blue{color: #2F5BC1 !important;background: #DEE8FF !important;}

.main .section.visual3 .action .controls button{position: relative;border: 0 none;background: none;padding: 0 none;cursor: pointer;z-index: 1;width: 2rem;height: 2rem;line-height: 2rem;}
.controls .arrow:before, .popupzone .controls .con_btn:before {content: '\EFDA';font-family: "remixicon";display: block;width: 100%;height: 100%;color: #000;font-size: 1.6rem;}
.controls .arrow.prev:before {content: '\EA64';}
.controls .arrow.next:before {content: '\EA6E';}
.popupzone .controls .con_btn.play{opacity: 0;overflow: hidden;display: none;}
.popupzone .controls .con_btn.stop{opacity: 0;overflow: hidden;display: none;}
.popupzone .controls .con_btn.stop.active{opacity: 1;overflow: visible;display: inline;}
.popupzone .controls .con_btn.play.active{opacity: 1;overflow: visible;display: inline;}
.popupzone .controls .con_btn.play.active:before {content: "\F012";}
.activecomment{background: #040860;padding: 1rem;width: 100%;text-align: left;font-weight: 600;color: #fff;border-bottom-left-radius: 1rem;border-bottom-right-radius: 1rem;transition: 'opacity 1s ease-in-out';top : 24rem;position: absolute;font-size: 1.3rem;}
.popupzone .swiper.active {opacity: 1;z-index: 1;}
.popupzone .swiper.inactive {opacity: 0;z-index: 0;}
.popupzone .swiper {margin-left: auto !important;margin-right: auto !important;position: relative !important;overflow: hidden !important;list-style: none !important;padding: 0 !important;z-index: 1 !important;position: absolute !important;top: 0 !important;left: 0 !important;width: 100% !important;height:24rem !important;transition: opacity 1s ease-in-out !important;}
.swiper-pointer-events {touch-action: pan-y;}
.main .section.visual3 .actives{width: calc(100% - 54.78571% - 0.1rem);}
.main .section.visual3 .popupzone{position: relative;display: block;width: 100%;height: 27.3rem;}
.main .section.visual3 .actives{position: relative;padding: 2rem;border: 0.07rem solid #e2e2e2;border-radius: 1rem;}
.main .section.visual3 .popupzone .controls{position: absolute;top: -4rem;right: 0rem;}
.main .section.visual3 .popupzone .controls button{padding: 0;border: 0 none; background: none;}
.main .section.visual3 .popupzone img{width: 100%;}
.popupzone .pop_slide_wrap{position: relative;}
.popupzone .pop_slide{height: 24rem;border-top-right-radius: 1rem !important; border-top-left-radius: 1rem !important;}


.main .section.visual4 {padding: 0rem 0 5rem;}
.main .section.visual4 .title{text-align: left;}
.main .section.visual4 .title p{font-size: 2rem;font-weight: 800;color: #007bff;}
.main .section.visual4 .title span{font-size: 1.2rem;}
.main .section.visual4 .wrap ul{list-style-type: none; padding: 0;display: flex;align-items: flex-start;justify-content: space-between;margin-top: 4rem;}
.main .section.visual4 .wrap ul li a{text-decoration: none; }
.main .section.visual4 .wrap-menu{text-align: left;background: #005eb8;border-radius: 1rem;padding: 2rem 3.6rem;width: 31%;transition: all 0.3s ease;height: 17rem;}
.main .section.visual4 .wrap-menu:hover{transform: translateY(-2rem);box-shadow: 0px 4px 10px rgb(9 26 70 / 78%);border-radius: 1rem;}
.main .section.visual4 .profile{background: #0086bf !important;}
.main .section.visual4 .sponser{background: #003f77 !important;}
.main .section.visual4 .wrap-menu a{color: #fff;font-size: 1.3rem;font-weight: 700;}
.wrapLink{font-size: 1rem;margin-top: 4rem;position: relative;}
.main .section.visual4 .wrap-menu:hover .wrapLink::after{content: "";position: absolute;bottom: -2.7rem; left: 0;width: 100%;height: 1px; background-color: #fff;animation: underlineEnter2 0.8s forwards; transform: translateY(-2em);}
.main .section.visual4 .wrap-menu span{font-size: 1rem;font-weight: 500;display: block;height: 2rem;}
.main .section.visual4 .wrap-menu:hover {box-shadow: 0px 4px 10px rgba(9, 26, 70, 0.78);border-radius: 1rem;}
.main .section.visual4 .sponser:hover {transform: translateY(-2rem);}
.sr-only{overflow: hidden;position: absolute;width: 1px;height: 1px;margin: -1px;padding: 0;border: 0 none;clip: rect(0,0,0,0);}

@media (min-width: 576px) {
  .container, .container-sm {
      max-width: 100% !important;
  }
}
@media (min-width: 768px) {
  .container, .container-md, .container-sm {
      max-width: 100% !important;
  }
  
}
@media (max-width: 768px) {
  .header.white.hover .depth1, .header.white.hover .depth2 a {color: #fff;}
  .header .container {padding: 0 !important;}
  .header .logo img {width: 138px;}
  .header .headerbtn{font-size: 2.3rem;}
  .header .nav{width: 100%;}
  .header.white::before{background: rgb(8 15 61) !important;}
  .header-content .nav .gnb.click {width: 100%;position: absolute;display: grid;gap: 1.5rem;}
  .header-content .nav .gnb.click li {padding: 0 1rem;text-align: center;}
  .header-content .nav .gnb.click .depth2.hoverbtn ul li{padding: 0;}
  .header.hover::before {height: var(--mini-header-height, 100px) !important;}
  .depth1.underline::after {bottom: -2.3rem;}
  .header .depth1 {font-size: 23px;display: inline;}
  .header .header-content {height: var(--mini-header-height, 100px) !important;}
  .header .depth1 {position: relative;top: -13px;}
  .header.white .depth2 a {width: 100%;color: #fff !important;}
  .header.white.fixed .depth1, .header.white.fixed .depth2 a {position: relative;top: -17px;color: #fff;}
  .header.click::before {position: absolute;width: 100vw;left: 0rem !important;height: 100vh !important;    background: rgb(8 15 61);}
  .header .depthbtn{opacity: 1 !important;visibility: 1 !important;position: absolute;right: 0;top: 0% !important;}
  .header .depthbtn.hover{color: #007bff !important;}
  .depth2.hoverbtn {display: block;position: relative;opacity: 1 !important;visibility: visible !important;transform: translateY(0); }
  .depth2.hover ul{position: relative;left: 40px;top: 2px;margin: 10px 0;}
  .header .depth2{transition: height 0.1s ease, opacity 0.1s ease;transform: translateY(0em) !important;width: 100%;}
  .header .depth2 ul {gap: .4333333333em;display: flex;place-items: center;text-align: right;flex-direction: column;justify-content: center;}
  .main_wrap.hitory {max-width: calc(100% - 1rem);}
  .main .main_wrap {padding: 0 .3rem;}
  .main_wrap .about-l h1{font-size: 2rem !important;}
  .main .comma {font-size: .9rem;top: 8px;}
  .main .visual1 img {width: 4.5rem;height: 4.5rem;transform: scale(1.2);}
  .main .visual1 .about-text h2 {font-size: 1rem;}
  .main .visual1 .about-text p {font-size: .9rem;margin-top: 0.5rem;font-weight: 500;}
  .main .visual2 li {width: calc(60% - 2.8rem) !important;margin: 0;max-width: calc(57% - 2.8rem);}
  .main .visual2 .icon .icon-img {font-size: 3rem;}
  .main .visual2 .icon {width: 3.6rem;height: 3.6rem;}
  .main .visual2 .item {flex-direction: column;gap: .7rem;padding:1.1rem;}
  .main .visual2 .desc {padding-left: 0; text-align: center;}
  .main .visual2 .desc strong {font-size: 1rem;}
  .main .visual2 .desc p {line-height: 1rem;font-size: 0.8rem;}

  .main .section.visual3 .noitce {padding: 1rem 1rem;}
  .main .section.visual3 .noitce .visual3m {font-size: 1.4rem;margin-bottom: 0.7rem;}
  .main .section.visual3 .noitce em {top: 0px;font-size: .8rem;padding: .3rem 1rem;}
  .main .section.visual3 .noitce strong {font-size: 0.9rem;}
  .main .section.visual3 .noitce .ul li {padding: 0 0rem 0.2rem 3.5rem;line-height: 1.4;height: 2.5rem;}
  .main .section.visual3 .visual3m {font-size: 1.4rem;}
  .main .section.visual3 .actives {padding: 1rem;}
  .main .section.visual3 .noitce ul {
    height: 19.5rem;
}

  .activecomment {background: #040860;padding: .8rem;font-size: 0.9rem;}
  .main .section.visual4 .title p {font-size: 1.3rem;}
  .main .section.visual4 .title span {font-size: 1rem;}
  .main .section.visual4 .title .fw-normal a {font-size: 1rem;}
  .main .section.visual4 .wrap-menu a {font-size: 1rem;line-height: 1.2rem;}
  .main .section.visual4 .wrap ul {display: flex;align-items: center;justify-content: center;flex-direction: column;gap: 0.7rem;}
  .main .section.visual4 .wrap-menu {width: 100%;height: auto;max-width: 500px;}
  .main .section.visual4 .wrap-title{font-size: 1.5rem;}
  .main .section.visual4 .wrap-menu span {height: 0;}
  .main .section.visual4 {padding: 0rem 1rem 0rem;}
  .main .map-section{padding: 0 1rem;}
  .map-section p {font-size: 1.5rem !important;}
  .road_underline {width: 135px !important;}
  .footer .f-top .t-top-l {font-size: 1rem;}
  .footer .f-top .t-top-r {font-size: 1.1rem;}
  .f-bottom p,.f-bottom span{font-size: .8rem;}
  .footer .f-bottom span:not(:nth-child(3))::before {right: .9rem;top: 1px;}
  .footer .copyright {padding: 1rem 0 0 0;}
  .footer .main_wrap .f-top {padding: .5rem 0;}
  .footer .main_wrap .f-bottom {margin-top: .5rem;}
  .header-content button {top: 45% !important;padding: 0;}

  .main_wrap .summary_wrap .inner {padding: 0rem; flex-direction: column;}
  .main_wrap .summary_wrap .inner .ask {padding: 0rem;line-height: 1.5rem;font-size: 1rem;}
  .main_wrap .summary_wrap .inner .ask h1 {font-size: 1.4rem;}
  .main_wrap .summary_wrap .inner .img {width: 100%;}
  .main_wrap .summary_wrap {padding: 0;}
  .main_wrap .vision_wrap {width: 100%;padding: 0;}

  .main_wrap .intro .visual strong {bottom: 3.5rem;font-size: 2rem;}

  .main_wrap .hitory_wrap .title {font-size: 1.2rem;margin-bottom: 1rem;}
  .main_wrap .hitory_wrap .title span {font-size: 1rem;}
  .main_wrap .hitory_wrap .year {font-size: 1.6rem;}
  .main_wrap .intro .visual{height: 19.3rem;}
  .main_wrap .hitory_wrap .detail p {font-size: .9rem;}
  .main_wrap .hitory_wrap .list .y_block .right {padding-top: 2rem;}
  .main_wrap .hitory_wrap .num {font-size: .9rem;}
  .main_wrap .hitory_wrap ol {padding: 0rem !important;}
  .main_wrap .hitory_wrap .list .y_block .txt {max-width: 9rem;}
  .main_wrap .hitory_wrap .y_block {display: flex;align-items: center;}
  .main_wrap .greeting_wrap {padding: 0rem;margin: 0 auto;max-width: 101rem;}
  .flex {display: flex;justify-content: center;flex-direction: column;gap: 4rem;}
  .main_wrap .greeting_wrap .greeting_title {display: flex;align-items: center;justify-content: space-between;font-size: 1.5rem !important;}
  .main_wrap .greeting_wrap .img_top img {width: 100%;}
  .main_wrap .greeting_wrap .img_bottom p:nth-child(1) {font-size: 1.2rem;margin-bottom: .5rem;}
  .main_wrap .greeting_wrap .img_bottom p:nth-child(2), .greeting_wrap .img_bottom p:nth-child(3) {font-size: 0.8rem;}
  .main_wrap .greeting_wrap .ask {margin-left: 0rem;}
  .main_wrap .greeting_wrap .ask p + p {line-height: 1.5rem;}
  .main_wrap .greeting_wrap .ask p {font-size: 1rem;}

  .main_wrap .oraniztion_wrap .ask {padding: 0rem;font-size: 1rem;margin-bottom: 4rem;}
  .main_wrap .oraniztion_tit {font-size: 1.5rem !important;}

  .main_wrap{max-width: calc(100% - 1.6rem);}

  .vision_wrap .table_wrap {padding: 0rem;max-width: 349px;margin: 0 auto;}
  .vision_wrap .table_wrap table{min-width: 22rem;}
  .vision_wrap .table_wrap .table{padding: 0;}
  .vision_wrap .table_wrap thead{opacity: 0;visibility: hidden;border: none;line-height: 0;height: 1px;width: 1px;position: absolute;left: 0;top: 0;}
  .vision_wrap .table_wrap .table tbody .tbody_tr{display: flex;min-width: 21.9rem;align-items: baseline;gap: 1rem;padding: 1rem 0;padding-left: 9.3rem;min-height: 10rem;flex-direction: column;gap: 0;}
  .vision_wrap .table_wrap .table tbody .tbody_tr{display: flex;flex-direction: column;align-items: baseline;position: relative;width: 199px;max-width: 523px !important;}
  .vision_wrap .table_wrap .table tbody .tbody_tr td{color: #626262;padding: 0;text-align: left;}
  .vision_wrap .table_wrap .table tbody .tbody_tr .td1{font-size: 1.1rem;font-weight: bold;color: #000;position: relative;top: 35px;}
  /* .vision_wrap .table_wrap .table tbody .tbody_tr td:nth-child(3){position: relative;top: -21px;} */
  .vision_wrap .table_wrap .table tbody .tbody_tr.one_td .td2{top: 20px;}
  .vision_wrap .table_wrap .table tbody .tbody_tr .td2{position: relative;top: -15px;color: #040860;padding-bottom: .8rem;}
  .vision_wrap .table_wrap .table thead tr th {font-size: 1rem;}
  .vision_wrap .table_wrap .table tbody th,
  .vision_wrap .table_wrap .table tbody td{font-size: 0.9rem;}
  .vision_wrap .table_wrap .table tbody .nam{font-size: 1.2rem;}

  .vision_wrap .table_wrap .table.img img {width: 100%;height: 100%;object-fit: cover;object-position: center;border-radius: 50%;transform: scale(0.9);padding-top: 1rem;}
  .vision_wrap .table_wrap .table.img .img_tr.scale-none img{transform: scale(1);}
  .vision_wrap .table_wrap .table .img_tr{width: 7.6rem; overflow: hidden;border-radius: 50%;display: flex;justify-content: center;align-items: center;box-sizing: border-box;height: 8rem;position: absolute;left: .3rem;}
  .vision_wrap .table_wrap .table.img .img_tr.scale-none{width: 7.8rem;}

  .vision_wrap .table_wrap h4 {font-size: 1.4rem;}
  .vision_wrap .ask {text-align: justify;}


  .table-box thead {display: none;}
  .table-box tbody tr{display: flex;flex-direction: column;align-items: flex-start;padding: 1rem 0;;}
  .table-box tbody tr td{padding: 0;display: flex;align-items: baseline;}
  .table-box tbody tr td:last-child{border-bottom: 1px solid gray;display: block;width: 100%;text-align: left;padding-bottom: 1rem;}
  .table-box tbody tr td a {margin-left: 0;font-size: 1.1rem;font-weight: 700;}
  .table .table-box td:before {content: attr(data-label);font-weight: bold;margin-right: 10px; }
  .listbox .searchform {width: 100%;}
  .listbox .searchform .input-group .form-control:focus {outline: none;box-shadow: none;}
  .listbox .board-box{display: flex;flex-direction: column;align-items: flex-start;font-size: .8rem;}
  .listbox .board-box h5{font-size: 1rem;}
  .listbox .btn{font-size: .8rem;}

  .main_wrap .sponser_wrap .title {font-size: 1.3rem;margin-bottom: 0;}
  .main_wrap .sponser_wrap .quo {top: -9px;margin: 0 .3rem;}
  .main_wrap .sponser_wrap li {font-size: 1rem;padding-left: .2rem;padding-bottom: 2rem;padding-top: 2rem;}
  .main_wrap .sponser_wrap li .num {font-size: 1rem;margin: 0;}
  .main_wrap .sponser_wrap li:nth-child(1) p {font-size: 0.8rem;}
  .main_wrap .sponser_wrap li:nth-child(1) {gap: 1rem;}
  .main_wrap .sponser_wrap li::before {width: 0.5rem;height: 0.5rem;border: 2px solid #000080;top: 40px;left: -6px;}
  .main_wrap .sponser_wrap table th {padding: 0;font-size: .8rem;text-align: center;}
  .main_wrap .sponser_wrap table td {font-size: .7rem;padding: 1rem 2rem;}
  .main_wrap .sponser_wrap table tbody tr:nth-child(2) td:nth-child(2) strong {margin-bottom: .3rem;}
  .main_wrap .sponser_wrap table tbody tr:nth-child(2) td:nth-child(2) .s-txt {font-size: .6rem;}
  .main_wrap .sponser_wrap li p {font-size: .8rem;}
  .main .sponser_wrap {max-width: 1100px;}
  .main_wrap .appli_form div input {padding: 1rem;width: calc(100% - 1rem);height: 3rem;}
  .main_wrap .appli_form div label {width: 8rem;font-size: .9rem;padding: 1rem;height: 4.5rem;flex-shrink: 0;}
  .main_wrap .appli_form div {gap: 1rem;}
  .main_wrap .appli_form button {font-size: 1rem;letter-spacing: 0.3rem;padding: 1rem 2rem 1rem 2.3rem;margin: 2rem 0;}
  .main_wrap .appli_form div textarea {height: 13rem;}
  .main_wrap .applititle{font-size: 1rem;width: auto;margin: 2rem auto;font-weight: 600;}
}
@media (max-width: 1024px) {
  .main .main_wrap{min-width: 100%;}
  .main .visual1 {display: flex;flex-direction: column;gap: 2rem;}
  .main .visual2 ul {flex-wrap: wrap !important;}
  .header .depth1 {line-height: 3rem;position: relative;top: -9px;}
  .gnb .depth1[aria-expanded="true"] + .depth2 {opacity: 0;visibility: hidden;transform: translateY(-1.6em);}
  .gnb .depth1.hover .depth2{opacity: 1;visibility: visible;}
  .header-content .nav .gnb{opacity: 0;visibility: hidden;}
  .header .nav .gnb{opacity: 0;visibility: hidden;}
  .header-content .nav .gnb.click{opacity: 1;visibility: visible;right: 0;top: 6rem;}
  .header-content button{color: #fff;background: none 0;border: 0;font-size: 3rem;position: absolute;right: 0;top: 50%;transform: translate(0, -50%);opacity: 1;visibility: visible;}
  .header-content .nav .gnb li{opacity: 0;visibility: hidden;position: relative;}
  .header-content .nav .gnb.click li{opacity: 1;visibility: visible;}
  .header .container {padding: 0 2rem;margin: 0;}
  .header::before {transition: none;transition-delay: none;}
  .hitory_wrap ol {padding: 0 9rem !important;}
  .main .main_wrap .section.visual2 ul li {width: calc(53% - 2.8rem) !important;margin-top: 1rem;}
  .main .section.visual3{flex-direction: column;gap: 2rem;}
  .main .section.visual3 .noitce {width: 100%;}
  .main .section.visual3 .noitce ul li{padding: 0 1rem 1.2rem 5rem;text-align: left;}
  .main .section.visual3 .actives {width: 100%;}
  .main .section.visual4 .wrap-menu {padding: 1.9rem 1.2rem;}
  .wrapLink {margin-top: 6rem;}
  .main .visual2 ul li:hover {transform: translateY(-0.6rem);}
  .hitory_wrap ol {padding: 0 2rem !important;}
  .header.click::before {position: absolute;height: 100vh !important;opacity: 1 !important;visibility: visible !important;}
}
@media (max-width: 1425px) {
  .hitory_wrap .right .year::before {
    border-bottom: 0 none;
}
.hitory_wrap .left .year::before {
  border-bottom: 0 none;
}
}
@keyframes underlineEnter2 {
  from {
      width: 0;
  }
  to {
      width: 100%;
  }
}
.swiper-slide {
  visibility: visible !important; 
  opacity: 1 !important;
  display: block !important;
}

.visual5 .swiper{opacity: 1;}
.visual5 .footbanner {width: 100%;padding: 20px;}
.visual5 .swiper-wrapper{  display: flex; justify-content: space-between;}
.visual5 .swiper-container {width: 100%;height: auto;  -webkit-overflow-scrolling: touch; overflow: hidden;}
.visual5 .swiper-slide {   width: calc(100% / 2);box-sizing: border-box;}
.visual5 .swiper-slide img {width: 13rem;height: 8rem;object-fit: cover;border-radius: 1rem; }
.visual5 {padding-bottom: 0 !important;}
/****** notice ******/
.notice{width: 140rem;max-width: calc(100% - 4rem);margin-right: auto; margin-left: auto;position: relative;}
.notice .main_wrap{position: relative;width: 140rem;padding-bottom: 9rem;max-width: calc(100% - 4rem);margin-right: auto;margin-left: auto;position: relative;}
.notice .visual{position: relative;height: 26.3rem;margin-bottom: 5rem;color: #fff;text-align: left;}
.notice .img{width: 100vw; height: 100%;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);display: block;overflow: hidden;}
.notice .img img{max-width: inherit;width: 100%;height: 100%;object-fit: cover;transform: scale(1.1);animation: visual_scale 7s; animation-fill-mode: forwards;}

.page-link{border-radius: 50%;  line-height: 0;padding: .6rem !important; border: none!important;}
@keyframes visual_scale {
  100%{transform:scale(1)}
}

/****** greeting ******/
.greeting_wrap{padding: 0 8rem;margin: 0 auto;max-width: 101rem;}
.greeting_wrap .greeting_title{display: flex;align-items: center;justify-content: space-between;font-size: 2.7rem !important;font-weight: 700;color: #454444;}
.greeting_wrap .img_top img{width: 24rem;}
.greeting_wrap .img_bottom{margin-top: 1.5rem;}
.greeting_wrap .img_bottom p:nth-child(1){font-size: 1.5rem;margin-bottom: .5rem;}
.greeting_wrap .img_bottom p:nth-child(2){margin-bottom: .3rem;}
.greeting_wrap .img_bottom p:nth-child(2),
.greeting_wrap .img_bottom p:nth-child(3){letter-spacing: .1rem;}
.greeting_wrap .img_bottom p:nth-child(3){
  margin-left: 1.1rem;
}
.greeting_wrap .ask{margin-left:4rem;text-align: justify;}
.greeting_wrap .ask p{font-size: 1.2rem;color: #454444;}
.greeting_wrap .ask p + p{margin-bottom: 1.5rem;line-height: 2rem;}
.tit{font-weight: 700;}
.flex{display: flex;justify-content: center;}
/****** vision ******/
.vision_wrap img{width: 100%;max-width: 1200px;}
.vision_wrap{padding: 0 8rem;max-width: 1400px;margin: 0 auto;}

.vision_wrap .ask {font-size: 1.2rem;color: #454444;text-align: justify;}
.table_wrap {padding: 0 3rem;}
.table_wrap h4{text-align: left;width: 100%;max-width: 55rem;font-weight: 900;font-size: 1.9rem;}
.table_wrap .table{display: flex;flex-direction: column;align-items: center;padding: 5rem 0;}
.table_wrap .table caption:nth-child(1){position: absolute;top: 0;left: 0;overflow: hidden;line-height: 0;z-index: -1;width: 1px;height: 1px;}
.table_wrap .table table{width: 55rem;width: 100%;max-width: 55rem;}
.table_wrap .table thead tr th{color: #fff;background: #000080;font-weight: 700;text-align: center;font-size: 1.2rem;padding: 1rem 0;}
.table_wrap .table tbody td{padding: 1rem 0;font-size: 1.1rem;font-weight: 600;text-align: center;}
.table_wrap .table.img img{width: 9rem;}
.table_wrap .table table {border-collapse: collapse; border-spacing: 0 10px; width: 100%;}
.table_wrap .table table, tr, th {border: 1px solid #eeeded;position: relative;}
.table_wrap .table table td{font-size: 0.94rem;}
/****** summary ******/
.summary_wrap{margin-bottom: 9rem;padding: 4rem 0;display: flex;justify-content: center;}
.summary_wrap .inner{display: flex;align-items: center;justify-content: space-around;}
.summary_wrap .inner .ask{padding: 0 2rem;line-height: 2.2rem;font-size: 1.2rem;max-width: 36rem;text-align:justify;padding-left: 0;}
.summary_wrap .inner .ask h1{font-weight: 900;}
.summary_wrap .inner .img{width: 45%;position: relative;top: 2rem;max-width: 504px;}
.summary_wrap .inner .img img{width: 100%;border-top-right-radius: 30px;border-bottom-left-radius: 30px;}

/****** oraniztion ******/
.oraniztion_tit{font-size: 2.2rem !important;color: #454444;font-weight: 700;}
.oraniztion_wrap .ask{font-size: 1.2rem;color: #454444;padding: 0 10rem;}
/****** intro ******/
.intro .visual{position: relative;height: 26.3rem;margin-bottom: 5rem;color: #fff;text-align: left;}
.intro .visual strong{position: absolute;bottom: 7.5rem;left: 0;width: 100%;font-size: 4.9rem;font-weight: 800;}
.intro .visual .img{position: absolute;top: 50%;left: 50%;display: block;z-index: -1;width: 100vw;height: 100%;overflow: hidden;transform: translate(-50%, -50%);}
.intro .visual .img img{max-width: inherit;width: 100%;height: 100%;object-fit: cover;transform: scale(1.2);animation: visual_scale 7s;animation-fill-mode: forwards;}

/******hitory******/
.hitory_wrap{max-width: 1400px;margin: 0 auto;}
.hitory_wrap .title{font-weight: 800;font-size: 2rem;text-align: center;margin-bottom: 3rem;}
.hitory_wrap .title span{display: block;text-align: center;font-size: 1.3rem;font-weight: 800;color: #040860;}
.hitory_wrap ol{position: relative;list-style-type: none;padding: 0 16rem;padding-bottom: 6rem;}
.hitory_wrap ol::before{content: '';width: 1px;left: 50%;top: 0;transform: translateX(-50%);position: absolute;background: #D5D5D5;height: 100%; -webkit-print-color-adjust: exact;}
.hitory_wrap ol li{position: relative;}
.hitory_wrap .y_block{position: relative;height: 11rem;}
.hitory_wrap .y_block::before{content: "";position: absolute;width: 1rem;height: 1rem;border-radius: 50%;background: #c7d7f4;left: 50%;top: 50%;transform: translate(-50%, -50%);}
.hitory_wrap .y_block::after{content: "";position: absolute;width: 0.5rem;height: 0.5rem;border-radius: 50%;background: #000080;left: 50%;top: 50%;transform: translate(-50%, -50%);}
.hitory_wrap .list .y_block .txt{position: absolute;width:50%;max-width: 23rem;}
.hitory_wrap .list .y_block .right{right: 0;text-align: left;padding-top: 2rem;}
.hitory_wrap .list .y_block .left{left: 0;text-align: right;}
.hitory_wrap .year {font-size: 3rem;color: #040860;}
.hitory_wrap .num{font-style: normal;font-weight: 700;color: #005eb8;margin-right: .3rem;font-size: 1.2rem;}
.hitory_wrap .detail p{font-weight: 700;font-size: 1.2rem;}
.hitory_wrap .y_block.active::before {background: #000080;}
.hitory_wrap .y_block.active::after {background: #c7d7f4;}
.year{position: relative;}
.right .year::before{width: 3rem;z-index: -1;content: "";border-bottom: 1px dashed #000;position: absolute;left: -4.3rem;top: 3rem;}
.left .year::before{width: 3rem;content: "";border-bottom: 1px dashed #000;position: absolute;left: 8.9rem;top: 5rem;  }

/***** sponser *****/
.sponser_wrap{max-width: 101rem;width: 100%;margin: 0 auto;padding-bottom: 4rem;}
.sponser_wrap ul{padding: 0;margin: 0 auto;max-width: 1200px;}
.sponser_wrap li{list-style-type: none;font-size: 2rem;font-weight: 900;color: #454444;text-align: left;position: relative;padding-left: 2rem;border-bottom: 1px solid #b3b3b3ee;padding-bottom: 6rem;padding-top: 6rem;}
.sponser_wrap li:nth-child(1){display: flex;gap: 3rem;}
.sponser_wrap li .num{font-size: 2.5rem;font-weight: 900;}
.sponser_wrap li:nth-child(1) p{font-size: 1.6rem;font-weight: 500;}
.sponser_wrap li::before{
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border: 4px solid #000080;
  border-radius: 50%;
  top: 6.8rem;
  left: 0;
}
.sponser_wrap li p{font-weight: 400;font-size: 1.5rem;margin: 0;line-height: 1.9;}
.sponser_wrap li p:nth-child(1){margin-top: .9rem;}
.sponser_wrap .title{font-size: 3rem;font-weight: 900;max-width: 77rem;margin: 0 auto;margin-bottom: 7rem;}
.sponser_wrap .quo{color: #000080;position: relative;top: -1rem;margin: 0 2rem;}
.sponser_wrap table{margin-top: 1.1rem;border-collapse: collapse;width: 100%;text-align: left;}
.sponser_wrap table thead tr{border-bottom: 2px solid rgb(204, 204, 204);border-top: 1px solid #000;}
.sponser_wrap table th{padding: 0 4rem;font-weight: bold;background-color: rgb(249, 249, 249);font-size: 1.7rem;}
.sponser_wrap table tbody th{padding-top: 4rem;}
.sponser_wrap table td{padding: 10px;font-size: 1.5rem;padding: 2rem 3rem;}
.sponser_wrap table tbody tr td{border: none;}
.sponser_wrap table tbody tr{padding: 10px;}
.sponser_wrap table tbody tr:nth-child(2) td:nth-child(1){background-color: rgb(249, 249, 249);}
.sponser_wrap table tbody tr:nth-child(2) td:nth-child(2){border-top: 1px solid rgb(221, 221, 221);}
.sponser_wrap table tbody tr:nth-child(2) td:nth-child(2) strong{display: block;margin-bottom: 1rem;}
.sponser_wrap table tbody tr:nth-child(2) td:nth-child(2) .s-txt{font-weight: 400; font-size: 1.2rem;color: #005eb8;display: block;} 
.sponser_wrap table tbody tr:last-child{border-bottom: 1px solid #000;}

/****** appli ******/
.appli_form{max-width: 908px;}
.appli_form div{display: flex;gap: 3rem;align-items: baseline;}
.appli_form div:first-child label{border-top-left-radius: 30px;border-top-right-radius: 30px;}
.appli_form div:nth-child(6){align-items: center;}
.appli_form div:nth-child(6) label{border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;height: 15rem;}
.appli_form div + div{border-top: 1px solid #e8e8e8}
.appli_form div label{background: #f9f9f9;;width: 17rem;font-weight: 700;font-size: 1.1rem;padding: 1rem 0;height: 7rem;display: flex;align-items: center;justify-content: center;}
.appli_form div input{padding: 1rem 2rem;border-radius: 30px;border: 1px solid #e8e8e8;width: 36rem;height: 4rem;}
.appli_form div input:focus{outline: none;}
.appli_form div textarea{width: 36rem;height: 11rem;border-radius: 13px;border: 1px solid #e8e8e8;padding: 1rem 2rem;}
.appli_form button{border: none;background: #000080;color: #fff;font-size: 1.4rem;font-weight: 600;letter-spacing: .5rem;padding: 1rem 2rem 1rem 2.8rem;border-radius: 45px;margin: 5rem 0;}
.applititle{font-size: 2rem; width: 65rem; margin: 4rem auto; font-weight: 600;}
/****** footer ******/
.footer {position: relative; width: 100%;background-color: #333; color: white;text-align: center;padding: 20px 0;}
.footer .main_wrap{position: relative;width: 140rem;max-width: calc(100% - 4rem);margin-right: auto;margin-left: auto;position: relative;}
.footer .f-top{display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #fff;padding: 1.4rem 0;}
.footer .t-top-l{font-weight: 700;font-size: 1.6rem;}
.footer .t-top-r{display: flex;gap: 1rem;font-size: 1.8rem;}
.footer .f-bottom{text-align: left;}
.footer .f-bottom p {margin-bottom: 0;}
.footer .f-bottom span{position: relative;padding-right: 3px;padding-right: 2rem;}
.footer .f-bottom span:not(:nth-child(3))::before{position: absolute;content: "";height: 100%;border-right: 1px solid #fff;right: 0.8rem;}
.footer .copyright{text-align: left;padding: 3rem 0 0 0;color: rgba(255, 255, 255, 0.4);font-size: 0.8rem;}
.footer a{color: #fff;}
.footer .pwr{font-size: 0.8rem;}




/********* JACK *****************/

/* 페이징 */
.paging{
  list-style-type:none;
  display:flex;
  justify-content: center;   
  gap: .3rem;
  align-items: baseline;
  border-top:1px solid #ddd;
  
}
.paging li{
   font-size:16px;
   margin-left:5px;
   margin-right:5px;

}
.paging li a{
   color:#434c53;
   padding:3px 10px;

}
.paging a:hover{
  cursor: pointer;

}
.paging a{
  color:#333;
}

.paging a:hover,
.paging a.active{
   /* background:#000080;
   border-radius:5px;
   color:#fff;
   text-decoration:none; */
  background: none;
  color: #000;
  border: 0 none;
}
.total-pg-info{
  display: flex;
  text-align: left;
  border-bottom: 2px solid #333;
}
.listbox .btn_box:first-child{display: none;}
.listbox .btn{background: gray; border: 0 none;line-height: 1.6rem;color: #fff;}
.listbox .btn_box1 {display: flex;gap: .2rem;}
/** 검색 **/
.searchform{
   width:40%;
   margin-left:auto;
   margin-right:auto;
}
.dropdown-menu{
   min-width:95%;

}
.dropdown-item{
  padding: 0.25rem 1.1rem;
}
.input-group-prepend{
   position: relative;
}

/** list_box **/

.listbox{
   
   text-align: center;
    max-width: 1440px;
  margin: 0 auto;

}
.listbox h1{
    font-size:25px;
}

.listbox th{
    text-align:center;
    position:relative;
}


.listbox tr th{
  padding:20px;
  border-bottom: 1px solid #ddd;

}

.listbox tr th:first-child{
  padding-top: 10px;
}
.listbox tbody tr{
  color: #9a9da2;
 
}

.listbox th:not(:last-child)::after{
    content:"";
    display:block;
    background:#c5bebe;
    box-shadow:1px 0 0 #e8eaeb;
    position:absolute;
    right:0;
    top:18px;
}
.listbox td a{
    margin-left:15px;
    color:#1d1e1f;
    display:inline-block;
    max-width:380px;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    
}

.listbox tr {
  border-color:#fff;
}
.listbox td span{
    font-size:23px;
    margin-left:5px;
    vertical-align: top;
    line-height: 1;
    color: #565656;
}
.filetd{display: flex;align-items: baseline;}
.listbox td i{
    font-size:16px;
    margin-left:1px;
    vertical-align: top;
}

.title_box{
  border-top: 3px solid #333;
  border-bottom: 1px solid #333;
  padding: 10px;
  font-weight: bold;
}

.board-box{
  text-align: right;
}
.board-box span{
  margin-right:10px;

}
td:nth-child(2) { 
  text-align: left;
}


/** 파일 **/


.file-box{
  padding: 0 10px;
  border-bottom: 1px solid #eee;
  background: #f5f6fa;
  text-align: left;
}
.file-box ul{
  padding: 0;
}
.file-box li{
  list-style-type: none
}
.file-box li a{
   color : #333;
   text-decoration: none;
   font-weight: bold;
}

/*** 이미지 ***/
.image-gallery {
  margin-top: 1rem;
}

.image-gallery img {
  max-width: 800px;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
 
}
/** 내용 **/
.content-paragraph {
  margin:3px;
  padding: 0;
  text-align: left;
}

/** 버튼 **/
.btn_box{
  display: flex;
  justify-content: space-between;
}

/** map **/
.map-section p {
  font-size: 2rem;
  font-weight: 800;
  color: #007bff;
  text-align: start;
}
.road_underline{
  border-bottom: 2px solid #007bff;
  width:205px;
  margin-bottom: 20px;
}

/*languagebutton */
.greeting_title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.greeting_title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.language-toggle {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.language-toggle:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .greeting_title_container {
    flex-direction: column;
    align-items: flex-start;
  }

  .language-toggle {
    margin-top: 10px;
  }
}

/* vdo_Box */
.vdobox {
    top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: relative; /* 위치 설정 */

}

.vdobox video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 화면 크기에 맞게 비디오를 꽉 채움 */
}

@media screen and (max-width: 768px) {
  .vdobox {
    width:100%;
    height: 55vh; /* 모바일 환경에서는 높이를 줄임 */
  }
}

@media screen and (max-width: 480px) {
  .vdobox {
    width:100%;
    height: 30vh; /*ㄱ 작은 화면에서는 더 줄임 */
  }
}
.table_wrap td img {
  width: 144px; 
  height: 185px;
  object-fit: cover;
  display: block; /* 사진을 블록 레벨 요소로 변환하여 텍스트 오른쪽에 배치 */
  margin: 0 auto; /* 가운데 정렬을 위한 설정 */
}
@media (max-width: 768px){
  /* tbody 전체 */
  .table_wrap thead {
    display: none;
  }
  .table_wrap tbody {
    display: block;
    padding:0;
    width: 300px;
    justify-content: center;
    font-size:0.8rem;
  }
  /* 각 행을 개별적으로 관리 */
  .table_wrap tbody tr>td:not(:first-child){
    display:block;  
    padding: 0;
    /* margin-bottom: 30px;
    margin-top:40px; */
  }

}
